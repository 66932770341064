'use strict';

Gri.module({
  name: 'pdfbox-alpha',
  ieVersion: null,
  $el: $('.pdfbox-alpha'),
  container: '.pdfbox-alpha',
  fn: function () {
    var maxheight = 0;
    $('.pdfbox-alpha .text').each(function() {
      if(maxheight < $(this).height()){
        maxheight = $(this).height();
      }
    });
    $('.pdfbox-alpha .text').height(maxheight);
  }
});
